import {
  FetchCategoriesSort,
  FetchCategoriesSortTypes
} from '../categories/categoriesTypes';
import {
  FetchMaterialsSortTypes,
  FetchMaterialsSort,
  FetchMaterialsFilters
} from './materialsTypes';

import { words } from '../../locales/keys';

export const INITIAL_MATERIALS_FILTERS: FetchMaterialsFilters = {};
export const INITIAL_MATERIALS_SORT: FetchMaterialsSort = [
  FetchMaterialsSortTypes.CREATED_AT_DESC
];
export const INITIAL_MATERIALS_LIMIT = 72;

export const INITIAL_MATERIAL_BRANDS_FILTERS = {};
export const INITIAL_MATERIAL_BRANDS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_MATERIAL_BRANDS_LIMIT = 200;

export const INITIAL_MATERIAL_BRANDS_SELECTED_FILTERS = {};
export const INITIAL_MATERIAL_BRANDS_SELECTED_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_MATERIAL_BRANDS_SELECTED_LIMIT = 1000;
export const INITIAL_MATERIAL_BRANDS_SELECTED_PAGE = 1;

export const materialsSortI18nTextTypes = {
  [FetchMaterialsSortTypes.CREATED_AT_DESC]: words.sortByNewest,
  [FetchMaterialsSortTypes.SELECTED_MATERIALS_COUNT_DESC]: words.sortByMostUsed,
  [FetchMaterialsSortTypes.USERS_CHOSEN_MATERIALS_COUNT_DESC]:
    words.sortByMostLiked
};

export enum MaterialsPermissions {
  READ_SELF_MATERIALS_LIBRARY_INDEX_PAGE = 'read_self_materials_library_index_page',
  READ_USER_MATERIALS_LIBRARY_INDEX_PAGE = 'read_user_materials_library_index_page',
  READ_MATERIALS_INDEX_PAGE = 'read_materials_index_page',
  READ_MATERIAL_SHOW_PAGE = 'read_material_show_page',
  READ_MATERIAL_CREATE_PAGE = 'read_material_create_page',
  READ_MATERIAL_EDIT_PAGE = 'read_material_edit_page',
  READ_MATERIAL_PREVIEW_LINK = 'read_material_preview_link',
  READ_MATERIAL_FAVORITE_BUTTON = 'read_material_favorite_button',
  READ_MATERIAL_DOWNLOAD_BUTTON = 'read_material_download_button',
  READ_SELF_COMPANY_LIBRARY_MATERIAL_DOWNLOAD_BUTTON = 'read_self_company_library_material_download_button',
  READ_OTHER_COMPANY_LIBRARY_MATERIAL_DOWNLOAD_BUTTON = 'read_other_company_library_material_download_button',
  READ_MATERIAL_DELETE_BUTTON = 'read_material_delete_button',
  READ_MATERIAL_UPDATE_IMAGE_VERSION_BUTTON = 'read_material_update_image_version_button',
  READ_NEW_MATERIAL_BUTTON = 'read_new_material_button',
  READ_MATERIALS_CLIENTS_FILTER = 'read_material_clients_filter',
  READ_MATERIALS_FAVORITE_CHECKBOX_FILTER = 'read_materials_favorite_checkbox_filter',
  READ_MATERIALS_FAVORITE_BUTTON_FILTER = 'read_materials_favorite_button_filter',
  READ_MATERIALS_NDA_FILTER = 'read_materials_nda_filter',
  READ_MATERIALS_MY_LIBRARY_FILTER = 'read_materials_my_library_filter',
  READ_MATERIALS_BLOCKED_FILTER = 'read_materials_blocked_filter',
  READ_MATERIALS_CATEGORIES_FILTER = 'read_materials_categories_filter',
  READ_MATERIALS_TOTAL_COUNT = 'read_materials_total_count',
  READ_MATERIALS_IMAGE_SCALE = 'read_materials_image_scale',
  READ_MATERIALS_TOGGLE_VIEW_BUTTON = 'read_materials_toggle_view_button',
  READ_MATERIALS_LOAD_MORE_BUTTON_ITEMS_COUNT = 'read_materials_load_more_button_items_count',
  READ_MATERIAL_COPY_LINK_BUTTON = 'read_material_copy_link_button',
  READ_MATERIAL_EDIT_BUTTON = 'read_material_edit_button',
  READ_MATERIAL_SUBMIT_TO_TASK_OR_PROJECT_BUTTON = 'read_material_submit_to_task_or_project_button',
  READ_MATERIAL_SKU = 'read_material_sku',
  READ_MATERIAL_CLIENTS = 'read_material_clients',
  READ_MATERIAL_CATEGORIES = 'read_material_categories',
  READ_MATERIAL_UPLOADED = 'read_material_uploaded',
  CHANGE_MATERIAL_NAME = 'change_material_name',
  CHANGE_MATERIAL_CATEGORY_IDS = 'change_material_category_ids',
  CHANGE_MATERIAL_BRAND_ID = 'change_material_brand_id',
  CHANGE_MATERIAL_MAX_FILE_IDS = 'change_material_max_file_ids',
  CHANGE_MATERIAL_IMAGE_IDS = 'change_material_image_ids',
  CHANGE_MATERIAL_DEFAULT_IMAGE = 'change_material_default_image',
  CHANGE_MATERIAL_SKU = 'change_material_sku',
  CHANGE_MATERIAL_NDA = 'change_material_nda',
  CHANGE_MATERIAL_CLIENT_IDS = 'change_material_client_ids',
  READ_MATERIAL_ATTACH_BUTTON = 'read_material_attach_button'
}

export enum MaterialsCategories {
  CERAMICS = '7712',
  FABRIC = '7714',
  GLASS = '7715',
  IMPERFECTION = '7933',
  METAL = '7717',
  STONE = '7719',
  SURFACE_EXTERIOR = '8191',
  WALL_SURFACES = '7718',
  WOOD = '7720'
}
