import React from 'react';

import {
  MaterialID,
  MaterialUUID,
  MaterialNanoID
} from '../../../../../materialsTypes';
import { ClassName, GADataType, I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useSubmitMaterialToProjectOrTask } from '../../../../../hooks/useSubmitMaterialToProjectOrTask';

import { SelectProjectAndTaskModalButton } from '../../../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { materialsKeys, words } from '../../../../../../../locales/keys';

export interface SubmitMaterialToProjectOrTaskModalButtonMaterial {
  uuid: MaterialUUID;
  id: MaterialID;
  nanoId: MaterialNanoID;
}

interface SubmitMaterialToProjectOrTaskModalButtonProps {
  material: SubmitMaterialToProjectOrTaskModalButtonMaterial;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  className?: ClassName;
  tooltipPlacement?: TooltipPlacement;
  iconClassName?: ClassName;
}

function SubmitMaterialToProjectOrTaskModalButton({
  material,
  className,
  dataGa,
  i18nText,
  i18nTextClassName,
  iconClassName = 'h-5 w-5 stroke-1.75',
  tooltipPlacement = TooltipPlacement.LEFT
}: SubmitMaterialToProjectOrTaskModalButtonProps & GADataType) {
  const {
    submitMaterialToProjectOrTask,
    submitMaterialToProjectOrTaskError,
    submitMaterialToProjectOrTaskIsLoading,
    submitMaterialToProjectOrTaskReset
  } = useSubmitMaterialToProjectOrTask({ material });

  return (
    <SelectProjectAndTaskModalButton
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      dataGa={dataGa || 'materials-material-submit-to-task-or-project-button'}
      icon={IconsEnum.PAPER_CLIP_SOLID}
      iconClassName={iconClassName}
      i18nSubmitText={words.attach}
      i18nTitle={materialsKeys.attachSelected}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      tooltipI18nText={i18nText ? null : words.attach}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton
      isLoading={submitMaterialToProjectOrTaskIsLoading}
      errorMessage={submitMaterialToProjectOrTaskError}
      onClose={submitMaterialToProjectOrTaskReset}
      onSubmit={submitMaterialToProjectOrTask}
    />
  );
}

export default SubmitMaterialToProjectOrTaskModalButton;
