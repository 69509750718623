import isArray from 'lodash/isArray';
import omit from 'lodash/omit';

import { useReactQueryState } from '../../../common/hooks/base/reactQuery/useReactQueryState';

import {
  FetchMaterialsCacheKey,
  FetchMaterialsFilters,
  FetchMaterialsSort
} from '../../materialsTypes';

import { INITIAL_MATERIALS_SORT } from '../../materialsConstants';

const ignoredFilterNames = ['materialParentCategoryIds', 'materialCategoryIds'];

const defaultInitialFilters = { nda: false, blocked: false };

const defaultInitialSort = INITIAL_MATERIALS_SORT;

interface MaterialsFiltersCacheOptions {
  cacheKey: FetchMaterialsCacheKey;
  initialFilters?: FetchMaterialsFilters;
  initialSort?: FetchMaterialsSort;
}

function useMaterialsFiltersCache({
  cacheKey,
  initialFilters = defaultInitialFilters,
  initialSort = defaultInitialSort
}: MaterialsFiltersCacheOptions) {
  const filtersCacheKey = `${cacheKey}-filters`;
  const sortCacheKey = `${cacheKey}-sort`;

  const { value: cachedMaterialsFilters, setValue } =
    useReactQueryState<FetchMaterialsFilters>(filtersCacheKey, initialFilters);

  const { value: cachedMaterialsSort, setValue: setCachedMaterialsSort } =
    useReactQueryState<FetchMaterialsSort>(sortCacheKey, initialSort);

  return {
    defaultInitialFilters: initialFilters,
    cachedMaterialsFilters,
    cachedMaterialsSort,
    changeCachedMaterialsFilters: (
      changedFilters: FetchMaterialsFilters,
      removedFilterNames: string[] = []
    ) => {
      setValue(
        omit({ ...cachedMaterialsFilters, ...changedFilters }, [
          ...(isArray(removedFilterNames) ? removedFilterNames : []),
          ...ignoredFilterNames
        ])
      );
    },
    setCachedMaterialsFilters: (nextFilters: FetchMaterialsFilters) => {
      setValue(omit(nextFilters, ignoredFilterNames));
    },
    setCachedMaterialsSort
  };
}

export default useMaterialsFiltersCache;
