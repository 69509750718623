import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, GADataType, I18nText } from '../../../../../types';

import {
  FetchMaterialsCacheKeys,
  UpdateMaterialCacheAction
} from '../../../materialsTypes';

import { FetchMaterialsSetsCacheKeys } from '../../../../materialsSets/materialsSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useMaterialFavoriteButton from './useMaterialFavoriteButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { MaterialFavoriteButtonMaterial } from './MaterialFavoriteButton.types';

import { words } from '../../../../../locales/keys';

interface MaterialFavoriteButtonProps {
  material: MaterialFavoriteButtonMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialFavoriteButtonMaterial>;
  icon?: IconsEnum;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  className?: ClassName;
  iconClassName?: ClassName;
}

function MaterialFavoriteButton({
  material,
  materialsCacheKeys,
  materialsSetCacheKeys,
  updateMaterialCache,
  className,
  icon,
  i18nText,
  i18nTextClassName,
  iconClassName,
  dataGa
}: MaterialFavoriteButtonProps & GADataType) {
  const { handleToggleFavorite, toggleMaterialFavoriteLoading } =
    useMaterialFavoriteButton({
      material,
      materialsCacheKeys,
      materialsSetCacheKeys,
      updateMaterialCache
    });

  return (
    <PureTooltipIconButtonHelper
      dataGa={dataGa}
      className={className}
      icon={icon}
      tooltipI18nText={
        material.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleFavorite}
      iconClassName={iconClassName}
      disabled={toggleMaterialFavoriteLoading}
    />
  );
}

export default MaterialFavoriteButton;
